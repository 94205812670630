.background-screen {

    width: 100%;
    height: 100vh;
   
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;
}

.full-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.fade-in-out {
   
    transition: opacity 2s ease-in-out;
    opacity: 0;

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);

    width: 100%;
    height: 100%;
  
}

.visible {
    opacity: 1;
}

.hidden {
    opacity: 0;
}

.spinner {
    display: inline-block;
    position: relative;
    width: 256px;
    height: 256px;
  }
  
  .spinner .bounce1, .spinner .bounce2, .spinner .bounce3 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color:  #B29A26; 
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: bounce 2s infinite ease-in-out;
  }
  
  .spinner .bounce1 {
    animation-delay: -1s;
  }
  
  .spinner .bounce2 {
    animation-delay: -0.5s;
  }
  
  @keyframes bounce {
    0%, 100% { transform: scale(0); }
    50% { transform: scale(1); }
  }