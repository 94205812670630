
.login-base {
    width: 64px;
    height: auto;

    position: absolute;

    bottom: 32px; 
    left: 25px; 
    z-index: 2;
}



.login {
 
    position: absolute;


    background-color:transparent;
    list-style-type: none;

    padding-top: 10px;

    margin: 0;



}



.tooltip {
  
    background-color: #eadab2;
        color: #e05626;
        padding: 5px;
        border-radius: 5px;
}



