
.menu-base {
    width: 64px;
    height: auto;

    position: absolute;

    top: 25px; 
    left: 25px; 
    z-index: 2;
}

.menu-image {
  
    width: 64px;
    height: 64px;
   
  
}

.menu {
 
    position: absolute;


    background-color:transparent;
    list-style-type: none;

    padding-top: 10px;

    margin: 0;



}

.menu li {
    display: block;
  
    margin-top: 7px;

    position:relative;

    left: -32px
  }
  
.menu a {
    display: block;
    text-decoration: none;

    background-color:  #e05626;
    color: #eadab2;

    border-width: 3px;
    border-style: solid;
    border-radius: 64px;
    border-color: #eadab2;

    padding: 20px;
    text-align: center;

}

.menu a:hover {
    display: block;
    text-decoration: none;

    background-color: #eadab2;
    color: #e05626;

    border-width: 3px;
    border-style: solid;
    border-radius: 64px;
    border-color: #e05626;
   
    padding: 20px;
    text-align: center;

  }

