



.login-container {
    display: grid;
    place-items: center;
    height: 100vh;

  }
  
  .login-form {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    background-color: #e05626;
    opacity: 1.0;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    z-index: 2;
  }
  
  .login-form h1 {
    margin-bottom: 20px;
  }
  
  .login-form label {
    font-weight: bold;
  }
  
  .login-form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 300px;
  }
  
  .button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    margin-top: 20px;
  }
  
  .button-container button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    color: #e05626;
    background-color:  #eadab2;
    cursor: pointer;
  }
  
  /* .button-container button:last-child {
    background-color: #eadab2;
  } */



